* {
  box-sizing: border-box;
}

html {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
}

body {
  color: #333;
  background: #f5f6f9;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 700;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin-top: 0;
}

.App {
  box-shadow: 0 1rem 3rem rgba(0,0,0,.175);
  background: #fff;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
  font-weight: 400;
  font-size: 14px;
  padding: 40px;
  border-radius: 6px;
  margin-top: 0;
}

.container {
  max-width: 640px;
  margin: 40px auto;
}

.container.plain {

}

.container.plain,
.container.plain .App {
  box-shadow: none;
}

.container.plain .App {
  padding: 0;
  border: 0;
  background: transparent;
}

.formRow {
  margin-bottom: 25px;
}

.form-control {
  display: block;
  width: 100%;
  height: calc(2.25rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media screen and (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}

.form-control::-ms-expand {
  background-color: transparent;
  border: 0;
}

.form-control:focus {
  color: #495057;
  background-color: #fff;
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.form-control::-webkit-input-placeholder {
  color: #6c757d;
  opacity: 1;
}

.form-control::-moz-placeholder {
  color: #6c757d;
  opacity: 1;
}

.form-control:-ms-input-placeholder {
  color: #6c757d;
  opacity: 1;
}

.form-control::-ms-input-placeholder {
  color: #6c757d;
  opacity: 1;
}

.form-control::placeholder {
  color: #6c757d;
  opacity: 1;
}

.form-control:disabled, .form-control[readonly] {
  background-color: #e9ecef;
  opacity: 1;
}

select.form-control:focus::-ms-value {
  color: #495057;
  background-color: #fff;
}

.form-control-file,
.form-control-range {
  display: block;
  width: 100%;
}

select.form-control[size],
select.form-control[multiple] {
  height: auto;
}

textarea.form-control {
  height: auto;
}

input.form-control,
textarea.form-control,
select.form-control {
  border-color: #F8F8F8;
  background-color: #F8F8F8;
  margin-top: 5px;
}

.required {
  color: red;
}

button[type="submit"] {
  -webkit-appearance: none;
  display: block;
  width: 100%;
  padding: 15px 10px;
  border: none;
  background: #333;
  color: #fff;
  font-size: 16px;
  font-weight: 700;
  border-radius: 4px;
  cursor: pointer;
}

.lead {
  font-size: 1.25rem;
  font-weight: 300;
}

.lead.well {
  padding-bottom: 20px;
}

.header {
  padding: 30px 0;
  padding-top: 0;
  padding-bottom: 15px;
  text-align: center;
}

.header img {
  margin-bottom: 15px;
}

.tac {
  text-align: center;
}

.hidden {
  display: none!important;
  opacity: 0;
}

@media screen and (max-width: 992px) {
  .container {
    margin-top: 15px;
    margin-bottom: 15px;
  }
  .App {
    margin: 15px;
    margin-top: 0;
    padding: 20px;
  }
  .header {
    padding: 15px;
  }
}
